import React from 'react'

const Footer = class extends React.Component {
  render() {
    return (
    
        <footer className="footer is-dark">
            <div className="container">
            <div className="columns">
                <div className="column">
                    <div className="level-left">
                    <p className="level-item is-italic is-family-code" >This site is not affiliated with http://expresscv.com </p>
                    <a className="level-item" href="/privacy-policy/"> </a>
                    <a className="level-item" href="/privacy-policy/">Privacy Policy</a>
                   <a className="level-item" href="/terms-of-use/">Terms of Use</a>
                    <a className="level-item" href="/about/">About</a>
                    <a className="level-item" href="/contact/">Contact</a></div>
                </div>
                    <div className="column has-text-centered has-text-right-tablet">
                        <p className="subtitle is-6"> © Bold Limited. All Rights Reserved.</p>
                    </div>
                    </div>
                </div>
        </footer>
    )
  }
}

export default Footer
